import React from "react";
import services from "../../assets/img/web-service.svg";
import ecommerce from "../../assets/img/ecommerce.svg";
import qrcode from "../../assets/img/qrcode.svg";
import "./main.css";
import { useTranslation } from "react-i18next";
import Clink from "./components/Clink";
import SearchBar from "../MobilePage/components/searchbar";

import i18n from 'i18next';

export function MainHome() {
    let i=0;
    /* const imgbox  = document.getElementById("img_0"); */
    
    
    const newIntervalId2 = setInterval(() => {
        nextCard();
    }, 4000);
    
    function nextCard() {
        const cards = document.querySelectorAll('.img-content');
        cards[12-i].style.transform = "translateX(-50px)";
        cards[12-i].style.opacity = "0";
        cards[12-i].style.visibility = "hidden";
        
        i = i + 1;
        if (i > 12) {
            
            cards.forEach(card => {
                card.style.transform = 'none';
                card.style.opacity = "1";
                card.style.visibility = 'visible';
            });
            i = 0;
        }
        
    }

    
    const { t } = useTranslation();
    
    return (
    <div className="bg-white h-[100%]">
      <div id='SearchBar' className="sm:mt-[0.75rem] sm:w-full sm:px-[1rem] lg:mt-[-4.8rem] lg:w-1/2 absolute self-center sm:right-0 lg:right-[8rem]"><SearchBar /></div>
      <div className="flex sm:flex-wrap md:flex-wrap-reverse">
      <div className="flex flex-wrap w-full bg-teal-50 justify-center sm:px-[1rem] sm:pt-[2rem] lg:pt-[1rem] md:px-[4rem] pb-[4rem]">
        <div className="group block bg-white/100 text-[#116072] shadow-2xl mt-[4rem] md:mx-[2rem] sm:mx-0
                        p-[2rem] rounded-[1rem] cursor-pointer w-[350px]">
          <div className="flex m-auto p-0 mt-[-4rem] justify-center items-center h-[80px] w-[80px] rounded-full bg-white shadow-xl">
            <img className="w-[55px]" src={services}></img>
          </div>
          <div className="text-center h-[4.5rem] sm:text-[16px] md:text-[18px] xl:text-[22px] py-[1rem]
                          uppercase font-bold">            
            {t("S_BUSINESS")} 
          </div>
          
          <div className="text-[16px] h-[8.5rem] px-[1rem] text-justify font-semibold py-[1rem]">
            {t("CARD_2_TEXT_B")}
          </div>
          <div className="flex justify-center py-[1rem]"><Clink text={t("MORE")} href={"/demogov?lang="+i18n.language} /></div>
        </div>

        <div className="block bg-white text-[#116072] justify-center items-center shadow-2xl mt-[4rem] md:mx-[2rem] sm:mx-0
                        p-[2rem] rounded-[1rem] cursor-pointer w-[350px] ">
          <div className="flex m-auto p-0 mt-[-4rem] justify-center items-center h-[80px] w-[80px] rounded-full bg-white shadow-xl">
            <img className="m-auto p-0 w-[30px]" src={ecommerce}></img>
          </div>
          <div className="text-center h-[4.5rem] sm:text-[16px] md:text-[18px] xl:text-[22px] py-[1rem]
                          uppercase font-bold">            
            {t("S_SERVICE")} 
          </div>
          
          <div className="text-[16px] h-[8.5rem] px-[1rem] text-justify font-semibold py-[1rem]">
            {t("CARD_4_TEXT_B")}
          </div>
          <div className="flex justify-center py-[1rem]"><Clink text={t("MORE")} href={"/demostore?lang="+i18n.language}/></div>
        </div>

        <div className="block bg-white text-[#116072] justify-center items-center shadow-2xl mt-[4rem] md:mx-[2rem] sm:mx-0
                        p-[2rem] rounded-[1rem] cursor-pointer w-[350px]">
          <div className="flex m-auto p-0 mt-[-4rem] justify-center items-center h-[80px] w-[80px] rounded-full bg-white shadow-xl">
            <img className="m-auto p-0 w-[45px]" src={qrcode}></img>
          </div>
          <div className="text-center h-[4.5rem] sm:text-[16px] md:text-[18px] xl:text-[22px] py-[1rem]
                          uppercase font-bold">            
            {t("S_QR")} 
          </div>
          
          <div className="text-[16px] h-[8.5rem] px-[1rem] text-justify font-semibold py-[1rem]">
            {t("CARD_3_TEXT_B")}
          </div>
          <div className="flex justify-center py-[1rem]"><Clink text={t("MORE")} href={"/democafe?lang="+i18n.language}/></div>
        </div>

        {/* <div className="block bg-white text-[#116072] justify-center items-center shadow-md
                        p-[1rem] rounded-[1rem] cursor-pointer w-[25%]">
          <img className="m-auto p-0 w-[35px]" src={invoice}></img>
          <div className="text-center sm:text-[16px] md:text-[18px] xl:text-[22px] px-[1rem]
                          uppercase font-bold">            
            {t("S_PLATFORM")} 
          </div>
          
          <div className="text-[16px] px-[1rem] text-justify font-semibold">
            {t("CARD_1_TEXT_B")}
          </div>
        </div> */}
        
      </div>
      
      {/*  <div className="bg-[#d6dadb7a] flex flex-wrap bg-white justify-evenly mb-[3rem]"> <Clink text={t("MORE")} href="#businessdemo" /> </div> */}
     
      
      <div className="flex w-full sm:flex-wrap md:flex-nowrap xl:px-[2rem] bg-white py-[2rem] items-center sm:justify-center md:justify-between">
            <div className="block sm:p-[2rem] md:p-[4rem] "> 
              <div className="flex text-[#006174] uppercase font-bold text-[24px]">{t('GET_PAYMENT')}</div>
              <div className="text-[#006174] text-[18px]">{t('GET_PAYMENT_RAPID')}</div>
              
              <div className="flex justify-between pt-[2rem]">
                <img className="sm:h-[36px] md:h-[48px]" src="/assets/img/korti_milly.png"></img>
                <img className="sm:h-[32px] md:h-[48px]" src="/assets/img/visa.png"></img>
                <img className="sm:h-[30px] md:h-[42px]" src="/assets/img/mastercard.jpg"></img>
                <img className="sm:h-[32px] md:h-[40px]" src="/assets/img/ibt.png"></img>
              </div>
            </div>
            <div className="iphone">
                <img className="img-content" src="/assets/img/screen13.jpg"  onClick={(event) => nextCard()} />
                <img className="img-content" src="/assets/img/screen12.jpg"  onClick={(event) => nextCard()} />
                <img className="img-content" src="/assets/img/screen11.jpg"  onClick={(event) => nextCard()} />
                <img className="img-content" src="/assets/img/screen10.jpg"  onClick={(event) => nextCard()} />
                <img className="img-content" src="/assets/img/screen09.jpg"  onClick={(event) => nextCard()} />
                <img className="img-content" src="/assets/img/screen08.jpg"  onClick={(event) => nextCard()} />
                <img className="img-content" src="/assets/img/screen07.jpg"  onClick={(event) => nextCard()} />
                <img className="img-content" src="/assets/img/screen06.jpg"  onClick={(event) => nextCard()} />
                <img className="img-content" src="/assets/img/screen05.jpg"  onClick={(event) => nextCard()} />
                <img className="img-content" src="/assets/img/screen04.jpg"  onClick={(event) => nextCard()} />
                <img className="img-content" src="/assets/img/screen03.jpg"  onClick={(event) => nextCard()} />
                <img className="img-content" src="/assets/img/screen02.jpg"  onClick={(event) => nextCard()} />
                <img className="img-content" src="/assets/img/screen01.jpg"  onClick={(event) => nextCard()} />

                <img className="img-frame" src="/assets/img/iphone12.png" onClick={(event) => nextCard()} ></img>
            </div>
            <div>
              <div className="flex items-center justify-left sm:p-[1rem] lg:p-[2rem] sm:text-[16px] lg:text-[22px]" >
                <div className="flex rounded-full bg-cyan-800 text-[#fff] font-bold
                                sm:w-8 sm:h-8 lg:w-12 lg:h-12 items-center justify-center" > 1 </div>
                <div className="flex pl-[2rem] text-cyan-800"> {t("BUSINESS_STEP_1")} </div>
              </div>
              <div className="flex items-center justify-left sm:p-[1rem] lg:p-[2rem] sm:text-[16px] lg:text-[22px]" >
                <div className="flex rounded-full bg-cyan-800 text-[#fff] font-bold
                                sm:w-8 sm:h-8 lg:w-12 lg:h-12 items-center justify-center" > 2 </div>
                <div className="flex pl-[2rem] text-cyan-800"> {t("BUSINESS_STEP_2")} </div>
              </div> 
              <div className="flex items-center justify-left sm:p-[1rem] lg:p-[2rem] sm:text-[16px] lg:text-[22px]" >
                <div className="flex rounded-full bg-cyan-800 text-[#fff] font-bold  
                                sm:w-8 sm:h-8 lg:w-12 lg:h-12 items-center justify-center" > 3 </div>
                <div className="flex pl-[2rem] text-cyan-800"> {t("BUSINESS_STEP_3")} </div>
              </div>  
              <div className="flex items-center justify-left sm:p-[1rem] lg:p-[2rem] sm:text-[16px] lg:text-[22px]" >
                <div className="flex rounded-full bg-cyan-800 text-[#fff] font-bold sm:text-[16px] lg:text-[22px] 
                                sm:w-8 sm:h-8 lg:w-12 lg:h-12 items-center justify-center" > 4 </div>
                <div className="flex pl-[2rem] text-cyan-800"> {t("BUSINESS_STEP_4")} </div>
              </div>   
            </div>
            
            
        
        
      </div>
      </div>
      <div className="bg-teal-50 flex flex-wrap xl:px-[2rem] bg-white py-[2rem] items-center">
        <div className="flex flex-wrap justify-evenly w-full">
          <div className="flex flex-wrap w-[330px] h-[200px] rounded-[20px] shadow-2xl bg-black
                           font-bold p-[1.5rem] my-[1rem] text-[#ffcc00]">
            <div className="h-[100px] text-[18px]">{t("HOME_TRANSFER")} </div>
            <Clink color="white" text={t("SIGNUP")} />
          </div>
          <div className="flex flex-wrap w-[330px] h-[200px] rounded-[20px] shadow-2xl bg-[#ffcc00]
                           font-bold p-[1.5rem] my-[1rem] text-black">
            <div className="h-[100px] text-[18px]">{t("HOME_SUBSCRIPTION")}</div>
            <Clink text={t("SIGNUP")} />
          </div>
          <div className="flex flex-wrap w-[330px] h-[200px] rounded-[20px] shadow-2xl bg-white
                           font-bold p-[1.5rem] my-[1rem] text-sky-700">
            <div className="h-[100px] text-[18px]">{t("HOME_WMS")}</div>
            <Clink color="rgb(3 105 161 / var(--tw-text-opacity))" text={t("SIGNUP")} />
          </div>
        </div>
        <div className="flex flex-wrap justify-evenly w-full">
          <div className="flex flex-wrap w-[330px] h-[200px] rounded-[20px] shadow-2xl bg-cyan-800
                           font-bold p-[1.5rem] my-[1rem] text-white">
            <div className="h-[100px] text-[18px]">{t("HOME_CRM")}</div>
            <Clink color="white" text={t("SIGNUP")} />
          </div>
          <div className="flex flex-wrap w-[330px] h-[200px] rounded-[20px] shadow-2xl bg-[#384357]
                           font-bold p-[1.5rem] my-[1rem] text-[#ffcc00]">
            <div className="h-[100px] text-[18px]">{t("HOME_INVOICE")}</div>

            <Clink color="#ffcc00" text={t("SIGNUP")} />
          </div>
          <div className="flex flex-wrap w-[330px] h-[200px] rounded-[20px] shadow-2xl bg-[#ADD90D]
                           font-bold p-[1.5rem] my-[1rem] text-[#073d6a]">
            <div className="h-[100px] text-[18px]">{t("HOME_GOV")}</div>
            <Clink color="#073d6a" text={t("SIGNUP")} />
          </div>
        </div>
      </div>

      
      {/* bg-gradient-to-r from-black to-[#006174] */}
      <div className="lg:flex px-[2rem] md:block sm:block pt-[4rem] justify-between bg-cyan-800" >
        <div className="lg:w-[400px] md:w-full sm:w-full text-[18px] text-white text-justify sm:px-0 md:px-8 pb-[4rem]">
            
            <div className="flex justify-center text-[18px] text-white font-bold h-[60px] uppercase">
                <img className="w-[60px] p-[10px]" src="/assets/img/qr-code.svg"></img>
                <div className="flex items-center">{t("BENIFITS_1")}</div>
            </div>
            <div>{t("BENIFITS_1_TEXT")}</div>
        </div>
        <div className="lg:w-[400px] md:w-full sm:w-full text-[18px] text-white text-justify sm:px-0 md:px-8 pb-[4rem]">

            <div className="flex justify-center text-[18px] text-white font-bold h-[60px] uppercase">
                <img className="w-[60px] p-[10px]" src="/assets/img/quality.svg"></img>
                <div className="flex items-center">{t("BENIFITS_2")}</div>
            </div>
            <div>{t("BENIFITS_2_TEXT")}</div>
        </div>
        <div className="lg:w-[400px] md:w-full sm:w-full text-[18px] text-white text-justify sm:px-0 md:px-8 pb-[4rem]">
            
            <div className="flex justify-center text-[18px] text-white font-bold h-[60px] uppercase">
                <img className="w-[60px] p-[10px]" src="/assets/img/security.svg"></img>
                <div className="flex items-center" >{t("BENIFITS_3")}</div>
            </div>
            <div>{t("BENIFITS_3_TEXT")}</div>
        </div>
        
      </div>

    
    
    </div>
  );
}

export default MainHome;

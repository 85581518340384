import React from "react";
import "./egov.css";
import { uuidv4, numberWithSpaces, withRouter } from "./GlobalFunctions"
import { Component } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import StripeReceipt from "./Stripe/StripeReceipt";
import StripeForm from "./Stripe/StripeForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SearchBar from "../MobilePage/components/searchbar";
import EgovInvoice from "./Stripe/EgovInvoice";
import i18n, { t } from 'i18next';


const promise = loadStripe("pk_test_MAzwyHtpcZhFburhFWSLsm3c");
const antIcon = ( <LoadingOutlined style={{ fontSize: 24, }} spin /> );

class Egovdemo extends Component {
    constructor(props) {
        super(props);
        this.state ={
            isChoosed: false,
            isLoaded: false,
            serviceId: null,
            data: null,
            orderData: null
          };
    };

    componentDidMount() {
    
        this.getData();
        const queryParams = new URLSearchParams(window.location.search)
        i18n.changeLanguage(queryParams.get("lang"));
    };

    getData = () => {
        fetch('assets/json/egovcontent.json')
            .then((response) => response.json())
                .then((responseJson) => {
                    this.setState({ data : responseJson, isLoaded: true });
          
                    });
    };

    handleBuy = async(event) => {
        if(!this.state.serviceId) {
            window.alert(t('EGOV_CHOOSE_ALERT'));
            return;
        }
        var _lastname = document.getElementById('egov-lastname').value;
        var _firstname = document.getElementById('egov-firstname').value;
        var _phone = document.getElementById('egov-phone').value;


        document.getElementById("egovstep_1").style.opacity = "0.2";
        document.getElementById("egovstep_2").style.opacity = "1";
        document.getElementById("egov-infobox").style.display="none";

        const ord = this.state.data.items.filter(e => e.id === this.state.serviceId)[0];
        this.setState({
            isChoosed: true,
            orderData: {
                prompt_element_name: "egovstep",
                id: uuidv4(),
                merchant_name: this.state.data.merchant_name[i18n.language],
                merchant_type: this.state.data.merchant_type,
                merchant_logo: this.state.data.merchant_logo,
                merchant_address: this.state.data.merchant_address[i18n.language],
                merchant_tin: this.state.data.merchant_tin,
                customer_lastname: _lastname,
                customer_firstname: _firstname,
                customer_phone: _phone,
                item_description: ord['id'] + ' '+ord['name'][i18n.language] ,
                amount: ord['price'],
                item_id: ord['id'],
                buttonstyle: {
                    margin: "1.5rem",
                    width: "280px",
                    height: "40px",
                    padding: "0.5rem 0.8rem 0.5rem 0.8rem", 
                    borderRadius: "20px", 
                    borderStyle: "none", 
                    background: "#ecf0f3", 
                    color: "#033e598f", 
                    cursor: "pointer", 
                    fontSize: "14px",
                    fontWeight: "600",
                    letterSpacing: "1px",
                    textDecoration: "none",
                    textTransform: "uppercase",
                    boxShadow: "-3px -3px 7px #ffffff,3px 3px 5px #ceced1"
                },
                containerstyle: {
                    textAlign:"center", 
                    width:"420px", 
                    height:"240px", margin: "0", borderRadius: "10px",
                    backgroundColor: "transparent",
                    boxShadow: "-3px -3px 7px #ffffff, 3px 3px 5px #ceced1"
                } 
            
            }
            
            });
    };

    selectService = async(event) => {
        const _service = this.state.data.items.filter(e => e.id === event.currentTarget.value)[0];

        document.getElementById("egov-infobox-title").innerHTML = _service['name'][i18n.language];
        document.getElementById("egov-infobox-text").innerHTML = _service['description'][i18n.language];

        this.setState( {serviceId: event.currentTarget.value});

    };

    render() {
        const { isLoaded, data, orderData } = this.state;
        
        const { translation } = this.props;
        return (
        <div id="egovdemo" className="demo">
            <div className="title">{translation("DEMO_EGOV")}</div>
            <div id="egovstep_1" className="prompt-box" >
                <div className="prompt-step" > 1 </div>
                <div className="prompt-text"> {translation("STEP_1_EGOV")} </div>
            </div>

            <div id="egovstep_2" className="prompt-box" style={{opacity:"0.2"}}>
                <div className="prompt-step"> 2 </div>
                <div className="prompt-text"> {translation("STEP_2")} </div>
            </div>

            <div id="egovstep_3" className="prompt-box" style={{opacity:"0.2"}}>
                <div className="prompt-step"> 3 </div>
                <div className="prompt-text"> {translation("STEP_3")} </div>
            </div>
            <div className="xl:pb-[2rem] md:pb-[1rem] sm:pb-[1rem]"></div>
        <div className="egov-container">
            {!isLoaded 
            ?<Spin indicator={antIcon} />
            :<div style={{width:"100%"}}>
                <div className="egov-box">
                    <div className="egov-img">
                        <img src="/assets/img/tj_gerb.svg"></img>
                    </div>
                    <div className="egov-title">{data.merchant_name[i18n.language]}</div>
                    <div className="egov-searchbar"><SearchBar /></div>
                </div>
                <div className="egov-service xl:justify-between sm:justify-center md:justify-center">
                    <div className="egov-card">
                    {this.state.isChoosed
                    ? <EgovInvoice data={orderData}/>
                    :<div>
                        <select className="egov-selector upper" onChange={this.selectService}> 
                            <option value="" disabled selected>{translation('EGOV_CHOOSE')}</option>
                            {data.items.map ((item, index) =>(
                                <option value={item.id}> {item.name[i18n.language]} </option>
                            ))}
                        </select>
                        <div className="egov-flex">
                            <input id="egov-lastname" type="text" className="egov-input upper input-45" autocomplete="on" placeholder={translation('LASTNAME')}></input>
                            <input id="egov-firstname" type="text" className="egov-input upper input-45" autocomplete="on" placeholder={translation('FIRSTNAME')}></input>
                        </div>
                        <div className="egov-flex">
                            <input id="egov-phone" type="text" className="egov-input upper input-45" autocomplete="on" placeholder={translation('PHONE')}></input>                                
                            <input type="text" className="egov-input upper input-45" autocomplete="on" placeholder={translation('ADDR')}></input>
                        
                        </div>
                                
                            <div className="egov-flex item-center">
                            <button className="egov-button" onClick={this.handleBuy}>{translation("FILL")}</button>
                        </div>
                        
                        </div>
                    }
                    </div>
                    
                    <div className="egov-card flex-center">
                    {this.state.isChoosed
                    ? <Elements stripe={promise} >
                        <StripeForm data={ orderData }/>
                    </Elements>
                    :<div id="egov-infobox">
                        <div id="egov-infobox-title" className="egov-title">{translation("EGOV_ABOUT")}</div>
                        <div id="egov-infobox-text" className="egov-text">{translation("EGOV_ABOUT_TEXT")}</div>
                    </div>
                    }
                    </div>
                </div>
            </div>
            }
        </div>
       
        </div>
    );
    }

};

export default withRouter(Egovdemo);
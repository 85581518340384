import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";

import InvoiceFormIBT from "./components/MainPage/InvoiceIBT/InvoiceFormIBT";
import ReceiptForm  from "./components/MainPage/Receipt/Receipt";
import Receipt from "./components/MobilePage/Receipt";
import Notfound from "./components/MobilePage/Notfound";
import MainLayout from "./components/MainPage/MainLayout"
import { MainHome } from "./components/MainPage/Home"
import Nikestore from "./components/MainPage/Nikestore";
import Cafedemo from "./components/MainPage/Cafedemo";
import Egovdemo from "./components/MainPage/Egovdemo";




export default class App extends React.Component {
  render () {
    return (
      <>
        <div className="full-height">
          
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<MainHome />} />
              
              <Route path='tm/receipt/:invoiceId' element={ <Receipt />}    />
              <Route path='receipt/:invoiceId' element={ <ReceiptForm />}    />
              <Route path='invoice/:invoiceId' element={ <InvoiceFormIBT />} />
              <Route path='demostore' element={<Nikestore />} />
              <Route path='democafe' element={<Cafedemo />} />
              <Route path='demogov' element={<Egovdemo />} />
              
              <Route path='*' element={<Notfound status = {{'status':'404'}}/>} />
            </Route>
          </Routes>
        </div>
      </>
    );
  }
  
}

function Login() {
  window.location.replace("https://app.smartpay.tj");
  return null;
}

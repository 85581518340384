import React from "react";
import { Component } from "react";

import { useTranslation } from "react-i18next";
import "./clink.css";

export default function Clink(props) {
    const { t } = useTranslation();
    return (
        <>
        {props.type === 'card'
            ? <a className="card-button" href={props.href}> {props.text} </a>
            :<a className="learn-more" href={props.href}>
                <span className="circle" aria-hidden="true">
                    <span className="icon arrow"></span>
                </span>
                <span style={{color:props.color, width:"100%"}} className="button-text"> {props.text} </span>
            </a>
        }
        </>
        
    );
}

import React from "react";
import { Component } from "react";
import {
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import usericon from '../../../assets/img/user-data-icon.svg'
import './StripeForm.css'
import StripeReceipt from "./StripeReceipt";
import { uuidv4, numberWithSpaces, withRouter } from "../GlobalFunctions"
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = ( <LoadingOutlined style={{ fontSize: 24, }} spin /> );


function CheckoutForm (Component) {
  return function CheckoutComponent(props) {
    const stripe = useStripe();
    const elements = useElements();
    return <Checkout {...props} stripe={stripe} elements = {elements} />;
  }
}

class Checkout extends Component {
  
  constructor(props) {
    super(props);
    
    this.cardStyle = {
        style: { 
            base: {color: "#32325d", fontFamily: '"Helvetica Neue", Helvetica, sans-serif', fontSmoothing: "antialiased", fontSize: "14px", "::placeholder": {color: "#aab7c4",},},
            invalid: {color: "#fa755a", iconColor: "#fa755a",},
        },
        
        inputstyle: {
            padding:"1.5rem 0.5rem 0.5rem 0.5rem", 
        },
        containerstyle: this.props.data.containerstyle,
        buttonstyle: this.props.data.buttonstyle
        
        };
    
    this.state = {
      succeeded: false,
      error: null,
      processing: '',
      disabled: true,
      receipt_id: null,
      billingDetails_email: "",
      billingDetails_phone: "",
      billingDetails_name: "",

    };
  }

  
  handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    this.setState({disabled: event.empty});
    this.setState({error: (event.error ? event.error.message : "")});
  };

  handleSubmit = async (event) => {

    event.preventDefault();
    this.setState({processing: true});
    
    let response = await fetch ('https://smartpay.tj/subapi/stripe/payment_intents_test', {
      method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ 
          //invoice_number: this.props.data.invoice_number,
          merchant_name: this.props.data.merchant_name,
          id: uuidv4(),
          item_description: this.props.data.item_description,
          amount: this.props.data.amount,
          iso_currency: "TJS"
        })
    });

    

    if (response.ok) {
      let Intent = await response.json();
      
      const payload = await this.props.stripe.confirmCardPayment(Intent.clientSecret, {
        
        payment_method: {
          card: this.props.elements.getElement(CardElement),
          
        }
      });
  
      if (payload.error) {
        this.setState({error: `Payment failed ${payload.error.message}`});
        this.setState({processing: false});
      } else {
        this.setState({error: null});
        this.setState({processing: false});
        this.setState({succeeded: true});

        document.getElementById("payment-form").style.display="none";
        document.getElementById( this.props.data.prompt_element_name + "_2").style.opacity = "0.2";
        document.getElementById(this.props.data.prompt_element_name + "_3").style.opacity = "1";
        //response = await fetch ("https://smartpay.tj/subapi/stripe/charges/create_receipt/"+payload.paymentIntent.id);
        //if (response.ok) {
        //    let json = await response.json();
        //    this.setState({receipt_id: json.receipt_id});
        //}
      }
    }
  };

render() {
    const { translation } = this.props;

    return (
    <div>
    <form id="payment-form" onSubmit={this.handleSubmit} >
        <div id = "card-container" style ={this.cardStyle.containerstyle} >
            <div style = {this.cardStyle.inputstyle}>
              <div className="card-input-box">
                <div className="card-input-spacer">
                  <img src={usericon} className="card-input-cardholder"></img>
                </div>
                <input type="text" className="card-input" autocomplete="on" placeholder={translation('CARDHOLDER')}></input>
              </div>
            </div>
            <div style = {this.cardStyle.inputstyle}>
                <CardElement id="card-element" onChange={this.handleChange} options={this.cardStyle} />
            </div>
            
            <button style={this.cardStyle.buttonstyle} disabled={this.state.processing || this.state.disabled || this.state.succeeded} id="submit" >
            <span id="button-text">
                {this.state.processing 
                    ? (<Spin indicator={antIcon} />) 
                    : (translation('PAY') ) //+ ' ' +numberWithSpaces((this.props.data.amount/100).toFixed(2)) +' '+ translation('CURRENCY_1'))
                }
            </span>
            </button>
            
        </div>
        
        
                
                {/* Show any error that happens when processing the payment */}
        {this.state.error && ( window.alert(this.state.error) )}
                
                {/* Show a success message upon completion */}
        
                    

        </form>
        {this.state.succeeded && ( <StripeReceipt data={this.props.data} /> )}
        </div>
    );
}

}

export default withRouter(CheckoutForm(Checkout));


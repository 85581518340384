import React from "react";
import style from "./InvoiceIBT.module.css";
import i18n, { t } from 'i18next';

import { Component } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd'; 

import { numberWithSpaces, withRouter, API_KEY } from "../GlobalFunctions"

import { QRCode } from 'react-qrcode-logo';
import { BrowserView, MobileView } from 'react-device-detect';
import Notfound from '../../MobilePage/Notfound';

const antIcon = ( <LoadingOutlined style={{ fontSize: 24, }} spin /> );


export class InvoiceFormIBT extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isLoaded: false,
        data: null,
        payler_session: null,
        key: 'smartpay',
        invoice_total: 0,
        requestFailed: false,
    };
  }

  componentDidMount() {
    
    this.getData();
    
  }

  

  componentDidUpdate() {
    
  }

  getData = () => {
    
    fetch('https://smartpay.tj/subapi/payler/invoice/uuid/' + this.props.params.invoiceId, 
    { 
        method: "GET", 
        headers : {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
            'x-app-token': API_KEY(),
        }
    })

    .then((response) => {
      if(response.status === 200){
          return response.json();     
      }else {
          this.setState({ requestFailed: true })
          window.location.href = 'https://smartpay.tj/404'
      }
    })
        .then((responseJson) => {
          this.setState({ data : responseJson, isLoaded: true });
          
        });
  }

  startPayment = () => {
    let total_amount = this.state.data.amount_due;
    if(!this.state.data.bank_fee_included){
      total_amount = (this.state.data.amount_due / (1-this.state.data.bank_fee)).toFixed(0);
    }
    

    fetch("https://smartpay.tj/subapi/payler/getsession", 
    {
      method: "POST",
      credentials:"include",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json',
        'x-app-token': API_KEY(),
      },
      


      body: JSON.stringify({
        'amount': total_amount,
        'product': (this.state.data.subject[i18n.language] === null ? this.state.data.subject['tj'] : this.state.data.subject[i18n.language]),
        'lifetime': 21600,
        'invoice_number': this.state.data.invoice_uuid,
        'currency': 'TJS'
      })

    }
    
    )
    
    .then((response) => response.json())
      .then((responseJson) => {
        
        window.location.href = 'https://sandbox.payler.com/gapi/Pay?session_id='+responseJson.id;
        
    });
  
  }

  render () {
    const { isLoaded, data } = this.state;
    const { translation } = this.props;
    
    return (
      <div>
      
      <div id = "SubHeader" className="flex text-blue-50 bg-transparent">
        
      </div>
      <div id = "InvoiceContainer" className="block bg-white rounded-[10px] xl:mx-[15rem]">
        {!isLoaded 
          ? <Spin indicator={antIcon} />
          : <div>
            <div id = "InvoiceHeader" className = "sm:block md:flex bg-white rounded-t-[10px] sm:px-0 sm:py-[10px] md:py-[0.5rem] md:px-[4rem]">
              <div className="flex flex-wrap-reverse md:w-full sm:mt-[-2rem] md:mt-0">
                <div className="block w-full m-auto">
                  <div className="items-center justify-center text-[#397f90] w-full sm:text-[14px] md:text-[16px] font-medium">
                      <div className="block w-full text-center bg-white">
                        <div className="text-center upper text-[14px] font-semibold py-[10px]">{data.merchant_name[i18n.language]}</div>
                      </div>
                  </div>
                  <div className="w-full">
                    <div id = "InvoiceLineBreaker2" className="sm:flex md:hidden h-[2px] bg-gradient-to-r from-[#397f90] to-transparent" />
                    <div className="flex bg-white">
                      <div className="items-center justify-center text-[#397f90] w-full sm:px-[10px] md:px-0">
                        <div className="text-[12px] text-center py-[5px]" >
                          
                          { !data.merchant_city
                            ? `${data.merchant_address} ${translation('TEL')} 
                              ${data.merchant_phonenumber} ${translation('EMAIL')} ${data.merchant_email}`
                            :`${data.merchant_city.area[(i18n.language === 'ru' ? 0 : 1)]} 
                              ${data.merchant_city.name[(i18n.language === 'ru' ? 0 : 1)]} 
                              ${data.merchant_address} ${translation('TEL')} 
                              ${data.merchant_phonenumber} ${translation('EMAIL')} ${data.merchant_email}`
                          }
                        </div>
                      </div>
                    </div>
                    
                    <div id = "InvoiceLineBreaker2" className="sm:flex md:hidden h-[2px] bg-gradient-to-r from-[#397f90] to-transparent" />
                  </div>
                </div>
                <div className="flex w-full items-center justify-center ">
                  <div className="flex m-auto p-4 bg-white rounded-full md:border-0 
                                  sm:shadow-md md:shadow-none">
                      <img className="md:h-[72px] sm:h-[56px]" src={data.merchant_logo}></img>
                  </div>
                </div>
              </div>
              
              <div className="flex md:w-full sm:w-full ">
                <div className="sm:flex md:block sm:w-full md:w-auto m-auto sm:justify-between items-center md:justify-center text-[#397f90] text-[14px] font-medium">
                  
                  <div className="md:text-[18px] sm:text-[14px]">
                    {data.status === 'open'
                      ? <div className="sm:block md:flex">
                          <div className="hidden">{window.document.title = translation('INVOICE') +  ' ' + data.invoice_number}</div>
                          <div className="px-[10px]">{translation('INVOICE')}</div>
                          <div className="px-[10px]">{numberWithSpaces(data.invoice_number)}</div>
                          
                        </div>
                      : <div className="sm:block md:flex">
                          <div className="hidden">{document.title = translation('RECEIPT') +  ' ' + data.invoice_number}</div>
                          <div className="px-[10px]">{translation('RECEIPT') }</div>
                          <div className="px-[10px]">{numberWithSpaces(data.invoice_number)}</div>
                        </div>
                    }
                  </div>
                  <div className="sm:block md:flex">
                    <div className="px-[10px]">{translation('DATE')}</div> 
                    <div className="px-[10px]">{data.create_at.substring(0, data.create_at.indexOf('T'))}</div>
                  </div>
                  <div className="sm:block md:flex">
                    <div className="px-[10px]">{translation('TIN')}</div>
                    <div className="px-[10px]">{data.merchant_tin}</div>
                  </div> 
                  
                </div>
              </div> 
            </div>
            {!data.bill_to && !data.customer_phonenumber
              ?<div></div>
              : <div>
                  <div className="sm:h-0 md:h-[2px] bg-gradient-to-r from-[#397f90] to-transparent" /> 
                  <div className="sm:block md:flex bg-white justify-between sm:px-[10px] sm:pb-[10px] sm:pt-0 md:pt-[10px] md:px-[4rem] text-[#397f90]">
                    <div >{translation('BILL_TO')}: {data.bill_to}</div>
                    <div >{translation('PHONE')}: {data.customer_phonenumber}</div>  
                  </div>
                </div>
            }
            
            <div className="h-[2px] bg-gradient-to-r from-[#397f90] to-transparent" /> 
            <div id = "InvoiceDataHeader" className="flex bg-blue-50 md:px-[4rem] py-[10px] sm:px-[10px] sm:py-[5px] text-[13px] font-medium text-[#627377]">
              <div className="md:w-[50vw] sm:w-[40vw] text-left"> {translation ('DESCRIPTION')} </div>
              <div className="w-[10vw] text-center"> {translation ('Q_TY')} </div>
              <div className="w-[10vw] text-center"> {translation ('MEASURE')} </div>
              <div className="md:w-[15vw] sm:w-[20vw] text-right"> {translation ('PRICE')} </div>
              <div className="md:w-[15vw] sm:w-[20vw] text-right"> {translation ('AMOUNT')} </div>
            </div>
            <div className="h-[1px] bg-[#627377bf] sm:mx-[10px] md:mx-[3.5rem] " />
            <div> 
              { 
                data.description.map ((item, index) =>( 
                  
                  <div className="flex md:px-[4rem] py-[10px] sm:px-[10px] sm:py-[5px] text-[13px] font-light text-[#627377]">
                    <div className="md:w-[50vw] sm:w-[40vw] text-left "> {(item.service_name[i18n.language] !== '' ? item.service_name[i18n.language] : item.service_name['tj'])} </div>
                    <div className="w-[10vw] text-center"> {item.service_unit_qty} </div>
                    <div className="w-[10vw] text-center"> {item.service_unit} </div>
                    <div className="md:w-[15vw] sm:w-[20vw] text-right"> {numberWithSpaces((item.service_price/100).toFixed(2))} </div>
                    <div className="md:w-[15vw] sm:w-[20vw] text-right"> {numberWithSpaces((item.service_unit_qty * item.service_price/100).toFixed(2))} </div>
                  </div>   
                )) 
              } 
            </div>
            <div className="h-[1px] bg-[#627377bf] sm:mx-[10px] md:mx-[3.5rem] " />
            <div id = "InvoiceSummary" className="flex bg-blue-50 md:px-[4rem] sm:px-[10px] py-[2px] text-[13px] font-normal text-[#627377]">
              <div className="w-[80rem] text-right"> {translation('TOTAL_AMOUNT')} </div>
              <div className="w-[20rem] text-right"> {numberWithSpaces((data.amount_due/100).toFixed(2))} </div>
            </div>
            
              {!data.bank_fee_included
                ? <>
                  <div id = "PayedSummary" className="flex bg-blue-50 md:px-[4rem] sm:px-[10px] py-[2px] text-[13px] font-normal text-[#627377]">
                    <div className="w-[80rem] text-right"> {translation('BANK_FEE')} </div>
                    <div className="w-[20rem] text-right"> {numberWithSpaces(((data.amount_due/(1-data.bank_fee) - data.amount_due)/100).toFixed(2))} </div>
                  </div>
                  <div id = "PayedSummary" className="flex bg-blue-50 md:px-[4rem] sm:px-[10px] py-[2px] text-[13px] font-semibold text-[#627377]">
                    <div className="w-[80rem] text-right"> {data.status === 'open' ? translation('AMOUNT_TO_PAY') : translation('PAYED_STAMP')} </div>
                    <div className="w-[20rem] text-right"> {numberWithSpaces((data.amount_due/(1-data.bank_fee)/100).toFixed(2))} </div>
                  </div>
                </>
                : <>
                  <div id = "PayedSummary" className="flex bg-blue-50 md:px-[4rem] sm:px-[10px] py-[2px] text-[13px] font-semibold text-[#627377]">
                    <div className="w-[80rem] text-right"> {data.status === 'open' ? translation('AMOUNT_TO_PAY') : translation('PAYED_STAMP')} </div>
                    <div className="w-[20rem] text-right"> {numberWithSpaces((data.amount_due/100).toFixed(2))} </div>
                  </div>
                  </>
              }

              
            

            <div className="h-[2px] bg-gradient-to-r from-[#397f90] to-transparent" />
            
            {data.status === 'open'
              ?<div id = "InvoicePayment" className="sm:block md:flex bg-white text-center sm:justify-center md:justify-between items-center w-full md:px-[4rem] sm:p-[1rem]">
              <a className="flex justify-center text-center text-white rounded-full md:text-[18px] sm:text-[14px] py-2 px-8  bg-gradient-to-r from-black to-[#006174] hover:bg-gradient-to-l from-black to-[#006174]" 
                onClick={() => this.startPayment()}> 
                <div >
                {!data.bank_fee_included
                  ? <>{translation('PAY')} {numberWithSpaces((data.amount_due/(1-data.bank_fee)/100).toFixed(2))} Сомони</>
                  : <>{translation('PAY')} {numberWithSpaces((data.amount_due/100).toFixed(2))} Сомони</>
                }
                </div>
              </a>
              <div className="sm:hidden md:flex my-[2rem]">
                <QRCode value = { data.invoice_uuid } 
                        fgColor = "#093541"
                        eyeRadius = { 18 }
                        /*logoImage = "/assets/img/telegram.svg"
                        removeQrCodeBehindLogo = "true"*/
                        logoWidth={16}
                        qrStyle = "dots"
                
                />
              </div>
              </div>
              : <div className="flex justify-center items-center bg-white p-[20px]" style={{background:"#fff", padding:"20px"}}>
                <div className="stamp_m"> {translation('PAYED_STAMP')}</div>
                </div>

            }
            
            <div className="h-[2px] bg-gradient-to-r from-[#397f90] to-transparent" />
            <div id = "InvoiceFooter" className="flex p-[10px] text-justify text-[#006D7D] rounded-b-[10px] text-[14px]">
              {translation('INVOICE_FOOTER')}
            </div>
          </div>
          }
      </div>
      
      

      {/* <MobileView>
        
        {!isLoaded 
          ? <Spin indicator={antIcon} />
          : <div>
            <div id="MobileInvoiceContainer" className={style.container_m}>
              <div id = "MobileInvoiceHeader" style={{display:"inline"}} className = {style.invoiceheader}>
              <div className="title">
                <div className="mlogo">
                    <img style={{height:"82px"}} src={data.merchant_logo}></img>
                </div>
                </div>
                <div className={style.div__box_m}>
                  <div className={style.div__text_m}>
                    <div className="merchant">
                      <div className="mtitle">{data.merchant_name[i18n.language] !== '' ? data.merchant_name[i18n.language] : data.merchant_name['tj']}</div>
                      { !data.merchant_city
                        ? `${data.merchant_address} ${translation('TEL')} 
                          ${data.merchant_phonenumber} ${translation('EMAIL')} ${data.merchant_email}`
                        :`${data.merchant_city.area[(i18n.language === 'ru' ? 0 : 1)]} 
                          ${data.merchant_city.name[(i18n.language === 'ru' ? 0 : 1)]} 
                          ${data.merchant_address} ${translation('TEL')} 
                          ${data.merchant_phonenumber} ${translation('EMAIL')} ${data.merchant_email}`
                  }
                      
                    </div>
                  </div>  
                </div>

                <div className="mreceipt-info">
                <div>
                  <div>{data.status === "open"
                          ? translation('INVOICE')  
                          : translation('RECEIPT') 
                  }</div>
                  <div>
                      {numberWithSpaces(data.invoice_number)}
                  </div>
                </div> 
                <div>
                  <div> {translation('DATE')}</div>
                  <div> {data.create_at.substring(0, data.create_at.indexOf('T'))}</div>
                </div>
                
                <div>
                  <div>{translation('TIN')}</div>
                  <div>{data.merchant_tin}</div>
                  
                </div>
                  
              </div>
              { !data.bill_to ? <div className="border border-[#006174]"></div> :
                <div className={style.div__box_m2}>
                  <div style={{color:"white"}} className={style.div__text_m}>
                  
                  <span className={style.notes}>
                    {translation('BILL_TO')}: {data.bill_to}
                  </span> 
                  <span className={style.notes}>
                    {translation('PHONE')}: {!data.customer_phonenumber ?'' :numberWithSpaces(data.customer_phonenumber)}
                  </span>
                    </div>
                </div>
                
              }
              </div>
              
              <div id="MobileInvoiceData">
                
                <div id = "MobileInvoiceDataHeader" className={style.invoicedata_m}>
                  <div className={style.col1}> {translation ('DESCRIPTION')} </div>
                  <div className={style.col2}> {translation ('Q_TY')} </div>
                  <div className={style.col3}> {translation ('MEASURE')} </div>
                  <div className={style.col4}> {translation ('PRICE')} </div>
                  <div className={style.col5}> {translation ('AMOUNT')} </div>
                </div>
                <div className={style.pricebreaker_m} />

                <div> 
                { 
                  data.description.map ((item, index) =>(
                    
                    <div className={style.invoicedata_m}>
                      <div style={{fontWeight:"300", fontSize:"10px", textAlign:"justify"}} className={style.col1}> {(item.service_name[i18n.language] !== '' ? item.service_name[i18n.language] : item.service_name['tj'])} </div>
                      <div style={{fontWeight:"300", fontSize:"10px"}} className={style.col2}> {item.service_unit_qty} </div>
                      <div style={{fontWeight:"300", fontSize:"10px"}} className={style.col3}> {item.service_unit} </div>
                      <div style={{fontWeight:"300", fontSize:"10px"}} className={style.col4}> {numberWithSpaces((item.service_price/100).toFixed(2))} </div>
                      <div style={{fontWeight:"300", fontSize:"10px"}} className={style.col5}> {numberWithSpaces((item.service_unit_qty * item.service_price/100).toFixed(2))} </div>
                    </div>   
                  )) 
                } 
                </div>
                
                <div className={style.pricebreaker_m} />
                <div className={style.div__box_m2}>
                <div id = "MobileInvoiceSummary" className={style.invoicesummary_m}>
                  <div className={style.foot1}> {translation('TOTAL_AMOUNT')} </div>
                  <div className={style.foot2}> {numberWithSpaces((data.amount_due/100).toFixed(2))} </div>
                </div>
                {!data.bank_fee_included
                ? <>
                  <div id = "MobilePayedSummary" className={style.payedsummary_m}>
                    <div className={style.foot1}> {translation('BANK_FEE')} </div>
                    <div className={style.foot2}> {numberWithSpaces(((data.amount_due/(1-data.bank_fee) - data.amount_due)/100).toFixed(2))} </div>
                  </div>
                  <div id = "MobilePayedSummary" className={style.payedsummary_m}>
                    <div className={style.foot1}> {translation('AMOUNT_TO_PAY')} </div>
                    <div className={style.foot2}> {numberWithSpaces((data.amount_due/(1-data.bank_fee)/100).toFixed(2))} </div>
                  </div>
                </>
                : <>
                  <div id = "MobilePayedSummary" className={style.payedsummary_m}>
                    <div className={style.foot1}> {translation('AMOUNT_TO_PAY')} </div>
                    <div className={style.foot2}> {numberWithSpaces((data.amount_due/100).toFixed(2))} </div>
                  </div>
                  </>
                }
                
                </div>

              </div>
              
              {data.status === 'open'
              ?<div id = "MobileInvoicePayment" className={ style.payment_m } style={{display:"flex"}}>
                
                <a className={style.pay_button} onClick={() => this.startPayment()}> 
                  <div >
                    {!data.bank_fee_included
                      ? <>{translation('PAY')} {numberWithSpaces((data.amount_due/(1-data.bank_fee)/100).toFixed(2))} Сомони</>
                      : <>{translation('PAY')} {numberWithSpaces((data.amount_due/100).toFixed(2))} Сомони</>
                    }
                  </div>
                </a>
              
            
              </div>
              : <div className="center" style={{background:"#fff", padding:"10px"}}>
                <div className="stamp_m"> {translation('PAYED_STAMP')}</div>
              </div>
              

            }

            </div>
            
          </div>
        }
      </MobileView> */}
    
      </div>
      );

  } 
  
}


export default withRouter(InvoiceFormIBT) ;
import React from "react";
import { Component } from "react";
import './StripeForm.css'
import { QRCode } from 'react-qrcode-logo';

import { uuidv4, numberWithSpaces, withRouter, today } from "../GlobalFunctions"

class StripeReceipt extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null
        };
    };

    componentDidMount() {
        if (this.props.data.merchant_type === 'egov'){
            document.getElementById("egovstamp").style.opacity="1";
        }
        
    };

render() {
    const { translation } = this.props;

    return (
        <div className="receipt-container">
            <div className="cutted-edge"></div>
            <div className="receipt-box">
                <img src={this.props.data.merchant_logo}></img>
                <div className="receipt-linebreaker"></div>
                <div className="receipt-title center">{this.props.data.merchant_name}</div>
                <div className="receipt-text center">{this.props.data.merchant_address}</div>
                <div className="receipt-linebreaker"></div>
                <div className="receipt-item-box">
                    <div>
                    <div className="receipt-text left_80">{translation("RECEIPT")}</div>
                    <div className="receipt-text right upper">{this.props.data.id.substring(0, this.props.data.id.indexOf('-'))}</div>
                    </div>
                    <div>
                    <div className="receipt-text left_80">{translation("DATE")}</div>
                    <div className="receipt-text right">{today()}</div>
                    </div>
                    <div>
                    <div className="receipt-text left_80">{translation("TIN")}</div>
                    <div className="receipt-text right">{this.props.data.merchant_tin}</div>
                    </div>
                </div>
                <div className="receipt-linebreaker"></div>
                <div className="receipt-item-box">
                    <div className="receipt-text left_80">{this.props.data.item_description}</div>
                    <div className="receipt-text right">{numberWithSpaces((this.props.data.amount/100).toFixed(2)) +' '+ translation('CURR_ISO_1')}</div>
                </div>
                <div className="receipt-item-box" >
                    <div className="receipt-text left_80" style={{fontWeight:"600"}}>{translation("TOTAL_AMOUNT")}</div>
                    <div className="receipt-text right" style={{fontWeight:"600"}}>{numberWithSpaces((this.props.data.amount/100).toFixed(2)) +' '+ translation('CURR_ISO_1')}</div>
                </div>
                <div className="receipt-qr-box">
                    <QRCode style={{margin:"auto", height:"150px", width:"150px"}} value = { `${this.props.data.item_id}` } 
                        fgColor = "#232323c8"
                        eyeRadius = { 10 }
                        qrStyle = "dots"
                
                    />
                </div>
            </div>
            <div className="cutted-edge"></div>
        </div>
    );
}
}

export default withRouter(StripeReceipt);
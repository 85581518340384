import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import phoneIcon from "../../assets/img/phoneIcon.svg";
import emailIcon from "../../assets/img/email.svg";
import locationIcon from "../../assets/img/location.svg";
import "./main.css";
import { t } from "i18next";

export function Footer() {
  return (
    <>
      {/* <div className="footerm">
            <div>
                <div>{t("ADDRESS")}</div>
                <div>+992 44 640 0021</div>
                <div>support@smartpay.tj</div>

            </div>
            <div>
                <div>{t("TERMS")}</div>
                <div>{t("POLICY")}</div>
                <div>{t("RIGHTS")} {t("COMPANY")}</div>
            </div>
        </div> */}

      <div className="footerm bg-gradient-to-r from-black to-[#006174] w-full sm:px-6 md:px-10 xl:px-16 py-6 flex flex-col space-y-4">
        <div className="w-full flex sm:flex-col sm:space-y-3 md:space-y-0 md:flex-row sm:justify-center md:justify-between">
          <div className="flex flex-row space-x-2 items-center animation-blog">
            <img src={phoneIcon} alt="phone" />
            <a className="m-0 text-white">+992 44 640 0021</a>
          </div>
          <div className="flex flex-row space-x-2 items-center animation-blog">
            <img src={emailIcon} alt="email" />
            <a className="m-0 text-white">support@smartpay.tj</a>
          </div>
          <div className="flex flex-row space-x-2 items-center animation-blog">
            <img src={locationIcon} alt="location" />
            <a className="m-0 text-white">{t("ADDRESS")}</a>
          </div>
        </div>
        <hr />
        <div className="flex flex-row sm:space-x-0 md:space-x-4 sm:justify-between md:justify-end w-full">
          <div className="flex flex-col space-y-2">
            {/* <a className="text-xs text-white m-0 font-light">{t("TERMS")}</a> */}
            <p className="text-xs text-white m-0 font-light">
              {t("RIGHTS")} {t("COMPANY")}
            </p>
          </div>

          {/* <a className="text-xs text-white m-0 font-light">{t("POLICY")}</a> */}
        </div>
      </div>
    </>
  );
}

export default Footer;

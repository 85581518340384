import React from "react";
import "./cafe.css";
import { uuidv4, numberWithSpaces, withRouter, API_KEY } from "./GlobalFunctions"
import { Component } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { QRCode } from 'react-qrcode-logo';
import i18n, { t } from 'i18next';

const antIcon = ( <LoadingOutlined style={{ fontSize: 24, }} spin /> );
var ws = null;

class Cafedemo extends Component {
  
    constructor(props) {
      super(props);
      
      this.state ={
        isChoosed: false,
        isLoaded: false,
        data: null,
        order: [],
        isFilled: false,
        new_invoice_id: null,
        processing: false,
        payedSuccess: false,
        intervalId: 0,
        totalOrderAmount: 0
      };
    
    
    };
    
    componentDidMount() {

        const queryParams = new URLSearchParams(window.location.search)
        i18n.changeLanguage(queryParams.get("lang"));

        ws = new WebSocket("wss://smartpay.tj/telebot/ws?token="+API_KEY())
        this.getData();
        ws.onmessage = event => {
            if(event.data === 'processing'){
                this.setState({processing: true});    
            };

            if(event.data === 'success'){
                
                document.getElementById("cafestep_2").style.opacity = "0.2";
                document.getElementById("cafestep_3").style.opacity = "1";

                document.getElementById("qrcode-prompt").innerHTML = `Вы оплатили ${(this.state.order.reduce(function(sum, item) { return sum + (item.qty * item.price);}, 0)/100).toFixed(2)} сомони. Спасибо за покупку!`;
                document.getElementById("payment-spin").style.display = "none";
                document.getElementById("payment-accept").style.display = "inline-block";
                this.setState({payedSuccess: true});
                clearInterval(this.state.intervalId);
            };

            console.log(`[message] Data received from server: ${event.data}`);
        };
        
        
    };

    componentDidUpdate(prevProps, prevState){
        
    };
    
    getData = () => {
        fetch('assets/json/basilico.json')
            .then((response) => response.json())
                .then((responseJson) => {
                    this.setState({ data : responseJson, isLoaded: true });
          
                    });
    };

    handleFill = async(event) => {
        document.getElementById("cafestep_1").style.opacity = "0.2";
        document.getElementById("cafestep_2").style.opacity = "1";

        fetch('https://smartpay.tj/telebot/create_invoice', 
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
            'x-app-token': API_KEY(),
          },
    
          body: JSON.stringify({
            'merchant_id': this.state.data.merchant_id,
            'description': this.state.data.merchant_description,
            'items': this.state.order,
            'currency': 'TJS',
            'amount': this.state.order.reduce(function(sum, item) { return sum + (item.qty * item.price);}, 0)
          })
    
        })
            .then((response) => response.json())
                .then((responseJson) => {
                    this.setState({ new_invoice_id: responseJson });
                    //console.log(responseJson);
                    //ws.send(responseJson);
                    });
        
        const newIntervalId = setInterval(() => {
                ws.send(this.state.new_invoice_id);
            }, 10000); 
        this.setState({isFilled: true, intervalId: newIntervalId});
    };

    handleCancel = async(event) => {
        document.getElementById("cafestep_2").style.opacity = "0.2";
        document.getElementById("cafestep_1").style.opacity = "1";
        this.setState({order: []});
    };

    handleOrder = async(event) => {

        if(this.state.order.length === 4){ 
            window.alert("Внимание! В тестовой версии нельзя добавлять больше 4 позиции. Спасибо!");    
            return; 
        }
        let _order = this.state.data.items.filter(e => e.id === event.currentTarget.id)[0];
        this.setState({order: [...this.state.order, _order]});
        
        const ItemIndex = this.state.order.findIndex(element => element.id === _order['id'] );
        
        let newArray = [...this.state.order];
        if(newArray.length > 0 && ItemIndex != -1){
            newArray[ItemIndex] = {...newArray[ItemIndex], qty: newArray[ItemIndex].qty+1};
            this.setState({order: newArray});
        }

        

    };
    
    
    render() {
    
    const { isLoaded, data, order, isChoosed } = this.state;
    const { translation } = this.props;
    return (
    <div id="cafedemo" className="demo">
        <div className="title">{translation("DEMO_CAFE")}</div>
        <div id="cafestep_1" className="prompt-box" >
            <div className="prompt-step" > 1 </div>
            <div className="prompt-text"> {translation("CAFE_STEP_1")} </div>
        </div>

        <div id="cafestep_2" className="prompt-box" style={{opacity:"0.2"}}>
            <div className="prompt-step"> 2 </div>
            <div className="prompt-text"> {translation("CAFE_STEP_2_1")} 
                <a src="https://t.me/Smartpaytj_bot">@Smartpaytj_bot</a>
                {translation("CAFE_STEP_2_2")}
            </div>
        </div>

        <div id="cafestep_3" className="prompt-box" style={{opacity:"0.2"}}>
            <div className="prompt-step"> 3 </div>
            <div className="prompt-text"> {translation("CAFE_STEP_3")} </div>
        </div>
        <div className="xl:pb-[2rem] md:pb-[1rem] sm:pb-[1rem]"></div>
    <div className="cafe-container">
    {!isLoaded 
        ?<Spin indicator={antIcon} />
        :<div className="cafe-menu blackboard">
        {data.items.map ((item, index) =>(
           <div className="cafe-item">
                <div className="cafe-item-img">
                    <h2>{item.name}</h2>
                    <img id={item.id} src={item.content} alt={item.name} onClick={this.handleOrder}></img>
                    
                </div>
                <div className="cafe-item-content">
                    <div className="card-price">
                        <h3>{numberWithSpaces((item.price/100).toFixed(2))} {translation("CURRENCY_1")}</h3>   
                    </div>
                    
                </div>
                
           </div> 
        ))}
        
        
        </div>
    }
    <div className="cafe-terminal-frame"> 
        <div id="cafe-terminal" className="cafe-terminal"> 
            <div className="cafe-terminal-merchant-logo">
                <img src="/assets/img/basilico-logo.png"></img>
            </div>
            <div style={{fontWeight:"600", margin:"10px"}} className="upper">Кассовый терминал</div>
            <div className="receipt-linebreaker"></div>
        {this.state.order.length === 0
            ?<></>
            : <>{!this.state.isFilled
                ?(<>{this.state.order.map ((item, index) =>(
                    <>
                        <div id="orders-box" className="cafe-terminal-orders-box">
                            <div className="flex-start">
                                <div>{index+1}.</div>
                                <div>{item.id}</div>
                                <div className="order-name"> {item.name}</div>
                            </div>
                            <div className="flex-end">
                                <div className="order-name"> x {item.qty}</div>
                                <div className="order-price">{numberWithSpaces((item.price/100).toFixed(2))}</div>
                                <div className="order-price">{numberWithSpaces(((item.qty * item.price)/100).toFixed(2))}</div>
                            </div>
                        </div>
                        <div className="receipt-linebreaker"></div>
                    </>)
                    )}
                    <div className="cafe-terminal-orders-box total">
                        <div style={{fontWeight:"600"}}>Итого сумма: </div>
                        <div style={{fontWeight:"600"}}>
                            {this.state.order.reduce(function(sum, item) {
                                return sum + (item.qty * item.price)/100;
                                }, 0).toFixed(2)
                                } Сомони
                        </div>    
                    </div>

                    <div className="button-box">
                        <button className="green" onClick={this.handleFill}>{translation("FILL")}</button>
                        <button className="red" onClick={this.handleCancel}>{translation("CANCEL")}</button>
                    </div>    
                </>)
            :<>{this.state.processing
                ?<div className="center-block">
                    <div id='qrcode-prompt' className="payment-text">Вы отсканировали QR-код! Провидете оплату пожалуйста</div>
                    <div className="center-center">
                        <Spin id="payment-spin" indicator={antIcon} />
                        <img id="payment-accept" style={{display:"none"}} src='/assets/img/accept-icon.svg'></img>
                    </div>
                </div>
                : <><div id='qrcode-prompt' className="payment-text">Отсканируйте пожалуйста QR-код с помощью Tелеграм бота Smartpay!</div>
                    <div className="center">
                    <QRCode style={{margin:"auto", height:"200px", width:"200px"}} value = { `${this.state.new_invoice_id}` } 
                            fgColor = "#232323"
                            eyeRadius = { 12 }
                            qrStyle = "dots"
                            /*removeQrCodeBehindLogo = "true"
                            logoImage = "/assets/img/telegram.svg"*/
                    
                        />
                    </div>
                </>
                }
            </>
            } </>
        }   

            
        </div>
        <img className = "cafe-terminal-logo" src="/assets/img/smartpay_logo.svg" />
    </div>
    </div>
    
    </div>
      
    );
    }
    
}

export default withRouter(Cafedemo);
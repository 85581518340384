import React from "react";
import "./main.css";

import { t } from "i18next";

export function Prefooter() {

  return (
    <>
      <div className="bg-teal-50 flex flex-wrap md:justify-center text-sky-700 w-full">
      <div className="block sm:mx-[2rem] sm:mt-[2rem] lg:m-[2rem] lg:text-[16px] sm:text-[14px] sm:flex-[0_0_75%] md:flex-[0_0_35%] xl:max-w-[25%] xl:flex-[0_0_20%]">
        <div className="sm:text-[16px] lg:text-[22px] font-bold">{t('ABOUT')}</div>
        <div><a className="py-[5px] text-sky-700">{t('JOBS')}</a></div>
        <div><a className="py-[5px] text-sky-700">{t('CONTACTS')}</a></div>
        <div><a className="py-[5px] text-sky-700">{t('NEWS')}</a></div>
        <div><a className="py-[5px] text-sky-700">{t('TARIFFS')}</a></div>
        
      </div>
      <div className="block sm:mx-[2rem] sm:mt-[2rem] lg:m-[2rem] lg:text-[16px] sm:text-[14px] sm:flex-[0_0_75%] md:flex-[0_0_35%] xl:max-w-[25%] xl:flex-[0_0_20%]">
        <div className="sm:text-[16px] lg:text-[22px] font-bold">{t('SOLUTIONS')}</div>
        <div><a className="py-[5px] text-sky-700">{t('INTERNET_ACQUIRING')}</a></div>
        <div><a className="py-[5px] text-sky-700">{t('TELEGRAM_PAYMENT')}</a></div>
        <div><a className="py-[5px] text-sky-700">{t('PAY_TERMINALS')}</a></div>
        <div><a className="py-[5px] text-sky-700">{t('CRM_SYSTEM')}</a></div>
        <div><a className="py-[5px] text-sky-700">{t('ERP_SYSTEM')}</a></div>
        <div><a className="py-[5px] text-sky-700">{t('EGOV_SYSTEM')}</a></div>
        
      </div>
      <div className="block sm:mx-[2rem] sm:mt-[2rem] md:mb-[2rem] lg:m-[2rem] lg:text-[16px] sm:text-[14px] sm:flex-[0_0_75%] md:flex-[0_0_35%] xl:max-w-[25%] xl:flex-[0_0_20%]">
        <div className="sm:text-[16px] lg:text-[22px] font-bold">{t('DEMONTSTRATION')}</div>
        <div><a className="py-[5px] text-sky-700" href="/demostore" target="_blank">{t('DEMOSTORE')}</a></div>
        <div><a className="py-[5px] text-sky-700" href="/demogov" target="_blank">{t('DEMOEGOV')}</a></div>
        <div><a className="py-[5px] text-sky-700" href="/democafe" target="_blank">{t('DEMOCAFE')}</a></div>
        
      </div>

      <div className="block sm:mx-[2rem] sm:my-[2rem] lg:m-[2rem] lg:text-[16px] sm:text-[14px] sm:flex-[0_0_75%] md:flex-[0_0_35%] xl:max-w-[25%] xl:flex-[0_0_20%]">
        <div className="sm:text-[16px] lg:text-[22px] font-bold">{t('SUPPORT')}</div>
        <div><a className="py-[5px] text-sky-700" href="#" target="_blank">{t('USER_GUIDES')}</a></div>
        <div><a className="py-[5px] text-sky-700" href="#" target="_blank">{t('API_DOCS')}</a></div>
        <div><a className="py-[5px] text-sky-700" href="#" target="_blank">{t('TECH_SUPPORT')}</a></div>
        <div><a className="py-[5px] text-sky-700" href="#" target="_blank">{t('PRIVACY')}</a></div>
        <div><a className="py-[5px] text-sky-700" href="#" target="_blank">{t('AGREEMENT')}</a></div>

        
        
        
      </div>
    </div>
    </>
  );
}

export default Prefooter;

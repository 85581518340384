import React, { useState  } from "react";
import { Outlet, Link } from "react-router-dom";
import "./mainlayout.css";

import { useTranslation } from 'react-i18next';
import { TJ, RU } from 'country-flag-icons/react/3x2';
import { Footer } from "./Footer";

import Prefooter from "./Prefooter";

function MainLayout() {
    const { i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState('tj');
    const [isLangBarExpanded, setLangBarExpanded] = useState(false)

    function changeLanguage (lang) {
        setSelectedLang(lang);
        i18n.changeLanguage(lang);
        setLangBarExpanded(!isLangBarExpanded);
        
    }

    function CurrLang() {
        return (
            <>{i18n.language === 'tj'
                ?<TJ style={{width:"4rem"}} />
                :<RU style={{width:"4rem"}} />
                

            }
            </>
        );
    }

    return (
        
      
      <div id="main_container" className="main_container" >
        {!isLangBarExpanded
            ?<div className="flex w-full bg-gradient-to-r from-black to-[#006174] justify-between">
                <Link to="/"><img className="sm:h-[3rem] lg:h-[3rem] sm:my-[1rem] lg:my-[2rem] md:mx-[2rem] sm:mx-[10px]" src='/assets/img/smartpay_logo.svg'></img></Link>
                
                <div className="flagm flex cursor-pointer items-center sm:mr-[10px] md:mr-[20px] lg:mr-[2rem]" onClick={() => { if (document.getElementById('SearchBar')!=null) {document.getElementById('SearchBar').style.visibility = 'hidden';}  setLangBarExpanded(!isLangBarExpanded); }}>
                    <CurrLang></CurrLang>
                </div>
            </div>
            
            :<div className="flex w-full bg-gradient-to-r from-black to-[#006174] justify-between">
                
                <div className="flex w-full justify-around sm:h-[4rem] md:h-[5rem] lg:h-[6rem] my-[10px]">
                    <div className="flag_bar_item" onClick={() => {if (document.getElementById('SearchBar')!=null) {document.getElementById('SearchBar').style.visibility = 'hidden';}  changeLanguage ('tj')}}>
                        <TJ style={{width:"4rem"}} />
                    </div>
                    <div className="flag_bar_item" onClick={() => {if (document.getElementById('SearchBar')!=null) {document.getElementById('SearchBar').style.visibility = 'hidden';}  changeLanguage ('ru')}}>
                        <RU style={{width:"4rem"}} />
                    </div>
                    
                </div>
            </div>
        }
                
            
        

            
        
        <Outlet />
        <Prefooter />
        <Footer />
      </div>
    
      
    );
  }
  
export default MainLayout;
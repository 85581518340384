import React from "react";
import "./nike.css";
import { uuidv4, numberWithSpaces, withRouter } from "./GlobalFunctions"
import { Component } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import StripeReceipt from "./Stripe/StripeReceipt";
import StripeForm from "./Stripe/StripeForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import i18n, { t } from 'i18next';

const promise = loadStripe("pk_test_MAzwyHtpcZhFburhFWSLsm3c");

const antIcon = ( <LoadingOutlined style={{ fontSize: 24, }} spin /> );

class Nikestore extends Component {
  
    constructor(props) {
      super(props);
      
      this.state ={
        isChoosed: false,
        isLoaded: false,
        data: null,
        orderData: null,
        choosedSize: 0,
        prevEl: 0,
        choosedColor: "",
        choosedSizeElement: null
      };
    
    
    };

    componentDidMount() {
    
        this.getData();
        const queryParams = new URLSearchParams(window.location.search)
        i18n.changeLanguage(queryParams.get("lang"));
    }

    getData = () => {
        fetch('assets/json/nikecontent.json')
            .then((response) => response.json())
                .then((responseJson) => {
                    this.setState({ data : responseJson, isLoaded: true });
          
                    });
    }

    handleSizeChoose = async(event) => {
        if(this.state.prevEl) {document.getElementById(this.state.prevEl).style.background = "#fff";}
        const el = document.getElementById(event.currentTarget.id);
        el.style.background = "#9dbc28";
        let _color = event.currentTarget.id;
        _color = _color.substring(0, _color.indexOf('_'));
        this.setState({choosedSizeElement: event.currentTarget.id});
        this.setState({choosedColor: _color});
        this.setState({choosedSize: el.innerHTML});
        this.setState({prevEl: event.currentTarget.id});
    };

    handleBuy = async(event) => {
        const el = document.getElementById(event.currentTarget.id);
        let _color = event.currentTarget.id;
        let _id = _color.substring(_color.indexOf('_')+1, _color.length);
        _color = _color.substring(0, _color.indexOf('_'));
        
        if (_color !== this.state.choosedColor) {
            window.alert("Выбирете пожалуйста цвет");
            return;
        } 
        if(!this.state.choosedSize) {
            window.alert("Выбирете пожалуйста размер");
            return;
        }
        
        el.style.display = "none";
        
        let elemArray = document.getElementsByClassName('nike-card');
        
        for (const element of elemArray) {
            element.style.display = "none";
        }

        
        elemArray = document.getElementsByClassName('nike-span-size');
        
        for (const element of elemArray) {
            element.style.display = "none";
        }

        const ord = this.state.data.items.filter(e => e.id === _id)[0];
        let cardEl = document.getElementById(_id);
        cardEl.style.display = "flex";
        cardEl.className = "nike-card nike-card-hover";

        cardEl = document.getElementById(this.state.choosedSizeElement);
        cardEl.style.display = "inline-block";

        document.getElementById("demostep_1").style.opacity = "0.2";
        document.getElementById("demostep_2").style.opacity = "1";

        this.setState({
            isChoosed:true, 
            orderData: {
                prompt_element_name: "demostep",
                id: uuidv4(),
                merchant_name: this.state.data.merchant_name,
                merchant_type: this.state.data.merchant_type,
                merchant_logo: this.state.data.merchant_logo,
                merchant_address: this.state.data.merchant_address,
                merchant_tin: this.state.data.merchant_tin,
                item_description: ord['id'] + ' '+ord['name'] +', '+ ord['color']+ ' size: '+ this.state.choosedSize ,
                amount: ord['price'],
                item_id: ord['id'],
                buttonstyle: {
                    margin: "1.5rem",
                    width: "280px",
                    padding: "0.5rem 0.8rem 0.5rem 0.8rem", 
                    borderRadius: "10px", 
                    borderStyle: "none", 
                    background: "#9dbc28", 
                    color: "#fff", 
                    cursor: "pointer", 
                    fontSize: "14px",
                    fontWeight: "600",
                    letterSpacing: "1px",
                    textDecoration: "none",
                    textTransform: "uppercase"
                },
                containerstyle: {
                    textAlign:"center", 
                    width:"420px", 
                    height:"240px", margin: "0", borderRadius: "10px",
                    backgroundColor: "rgba(255, 255, 255, 0.6)"
                } 
          }});
    };

    
    
    render() {
    
    const { isLoaded, data, orderData } = this.state;
    const { translation } = this.props;
    return (
    <div id="businessdemo" className="demo">
            <div className="title">{translation("DEMO_ECOMM")}</div>
            <div id="demostep_1" className="prompt-box" >
                <div className="prompt-step" > 1 </div>
                <div className="prompt-text"> {translation("STEP_1")} </div>
            </div>

            <div id="demostep_2" className="prompt-box" style={{opacity:"0.2"}}>
                <div className="prompt-step"> 2 </div>
                <div className="prompt-text"> {translation("STEP_2")} </div>
            </div>

            <div id="demostep_3" className="prompt-box" style={{opacity:"0.2"}}>
                <div className="prompt-step"> 3 </div>
                <div className="prompt-text"> {translation("STEP_3")} </div>
            </div>
            <div className="xl:pb-[2rem] md:pb-[1rem] sm:pb-[1rem]"></div>
    <section>
    {!isLoaded 
        ?<Spin indicator={antIcon} />
        :<div className="nike-container">
        {data.items.map ((item, index) =>(
           <div id={item.id} className="nike-card">
                <div className="nike-img">
                    <img src={item.content} alt={item.name}></img>
                    <h2>{item.name}</h2>
                </div>
                <div className="nike-content">
                    <div className="nike-size">
                        <h3>{translation("SIZE")}</h3>
                        {item.sizes.map((size,index) => (
                            <span className="nike-span-size" id={item.color+'_'+index} onClick={this.handleSizeChoose}>{size}</span>
                        ))}
                    </div>
                    <div className="nike-price">
                        <h3>{translation("DEMOPRICE")}</h3>
                        <span>{numberWithSpaces((item.price/100).toFixed(2))} {translation("CURRENCY_1")}</span>
                    </div>
                    <button id = {item.color+'_'+item.id} onClick={this.handleBuy}>{translation("BUY")}</button>
                </div>
                
           </div> 
        ))}
        {!this.state.isChoosed
        ?<div></div>
        :<Elements stripe={promise} >
            <StripeForm data={ orderData }/>
        </Elements>

        }
        
        </div>
    }
    </section>
    </div>
      
    );
    }
    
}

export default withRouter(Nikestore);
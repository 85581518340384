import React, { Component }  from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CryptoJS from "crypto-js";

export function numberWithSpaces(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

export function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function today() {
  var _today = new Date();
  var dd = String(_today.getDate()).padStart(2, '0');
  var mm = String(_today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = _today.getFullYear();

  return dd + '.' + mm + '.' + yyyy;
}

export function API_KEY() {
  const d = new Date();
  const time = (d.getTime()/1000).toFixed(0);

  var iv = CryptoJS.lib.WordArray.random(16);
  //var key = CryptoJS.enc.Utf8.parse( 'secret' + time ); 
  var key = CryptoJS.enc.Base64.parse("aR1h7EefwlPNVkvTHwfs6w==");

  var encrypted = CryptoJS.AES.encrypt(
    '5fe322089e65f10c4f82e6aee55ba4f095012a90afcca9c97098325695107d40' + time,
      key,
      { iv: iv }
    );
  var joinedData = iv.clone().concat(encrypted.ciphertext);
  var joinedDataB64 = CryptoJS.enc.Base64.stringify(joinedData);
  
  return joinedDataB64;
  
}

export const withRouter = WrappedComponent => props => {
    const params = useParams();
    const { t } = useTranslation('','', '', { useSuspense: false });
  
    return (
      <WrappedComponent
        {...props}
        params={params}
        translation = {t}
        
        
      />
    );
  };

export default { uuidv4, today, numberWithSpaces, withRouter }
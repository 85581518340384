import React from "react";
import { Component } from "react";
import '../egov.css'
import { QRCode } from 'react-qrcode-logo';

import { uuidv4, today, numberWithSpaces, withRouter } from "../GlobalFunctions"

class EgovInvoice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null
        };
    };

render() {
    const { translation } = this.props;

    return (
        <div>
            <div className="egov-flex item-center">
                <div className="egov-logo-box"><img src={this.props.data.merchant_logo}></img></div>
            </div>
            <div className="egov-flex item-center">
                <h2>{this.props.data.merchant_name}</h2>
            </div>
            <div className="egov-linebreaker"></div>
            <div className="egov-flex">
                <div>{translation("INVOICE")}</div>
                <div>{this.props.data.id.substring(0, this.props.data.id.indexOf('-'))}</div>
            </div>
            <div className="egov-flex">
                <div>{translation("DATE")}</div>
                <div>{today()}</div>
            </div>
            <div className="egov-flex">
                <div>{translation("BILL_TO")}</div>
                <div>{this.props.data.customer_lastname+' '+ this.props.data.customer_firstname}</div>
            </div>
            <div className="egov-flex">
                <div>{translation("PHONE")}</div>
                <div>{this.props.data.phone}</div>
            </div>
            <div className="egov-linebreaker"></div>
            <div className="egov-flex">
                <div style={{width:"80%"}}>{this.props.data.item_description}</div>
                <div style={{display:"flex",alignItems:"center"}}>{numberWithSpaces((this.props.data.amount/100).toFixed(2)) +' '+ translation('CURR_ISO_1')}</div>
            </div>
            <div className="egov-linebreaker"></div>
            <div className="flex-center">
                <div style={{opacity:"0"}} id="egovstamp" className="egov-stamp"> {translation('PAYED_STAMP')}</div>
            </div>
            
        </div>
    );
}
}

export default withRouter(EgovInvoice);
import React from "react";
import { Component } from "react";
import { Spin } from 'antd';
import { withRouter, API_KEY } from "../GlobalFunctions"


export class Receipt extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            isLoaded: false,
            data: null,
        };
      }

    componentDidMount() {
        this.getPaymentStatus();
      }

    getPaymentStatus = () => {

        fetch("https://smartpay.tj/subapi/payler/getadvancedstatus", 
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
            'x-app-token': API_KEY(),
          },
    
          body: JSON.stringify({
            'invoice_number': this.props.params.invoiceId
          })
    
        })
        
        .then((response) => {
          if(response.status === 200){
              return response.json();     
          }else {
              this.setState({ requestFailed: true })
              window.location.href = 'https://smartpay.tj/404'
          }
        })
          .then((responseJson) => {
            this.setState({ data : responseJson, isLoaded: true });
        });
      
      } 
    
    render () {
        const { isLoaded, data } = this.state;
        return (
          <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100vh"}}> 
                { !isLoaded 
                    ? <Spin />
                    : window.location.href = `${window.location.protocol}//${window.location.hostname}/invoice/` + this.props.params.invoiceId
                }
          </div>
        );
    }
}

export default withRouter(Receipt) ;